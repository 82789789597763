<template>
    <main class="main">
        <nav class="breadcrumb-nav">
            <div class="container">
                <Breadcrumbs />
            </div>
        </nav>

        <div class="page-content">
            <div class="container bg-white p-5 rounded-corner">
                <div class="row skeleton-body" :class="{ loaded: loaded }">
                    <div class="col-lg-12">
                        <template v-if="!loaded">
                            <div class="skel-list-post mb-6" v-for="(i, index) in [1, 2, 3, 4, 5, 6]"
                                :key="'skel' + index">
                            </div>  
                        </template>

                        <template v-else>
                            <p class="blogs-info" v-if="cmsPageContent === null">No blog were found matching your
                                selection.</p>
                            <div v-else>
                                <div class="row">
                                    <div class="col-md-12 px-3">
                                        <h4 class="text-primary">{{ cmsPageContent.title }}</h4>
                                        <div class="entry-meta">
                                            <a :href="cmsPageContent.author.author_url" target="_blank">
                                                <!-- <font-awesome-icon icon="fa-user" /> -->
                                                <img class="d-inline"
                                                    src="@/esf_weert_mobilesupplies/assets/static/images/icons/mw.png"
                                                    alt="">
                                                <span> Geschreven door <span class="text-capitalize">{{
                    cmsPageContent.author.name }}</span></span>
                                            </a>
                                            <router-link to="">
                                                <!-- <font-awesome-icon icon="fa-calendar" /> -->
                                                <img class="d-inline"
                                                    src="@/esf_weert_mobilesupplies/assets/static/images/icons/calendar.png"
                                                    alt="">
                                                <span class="text-capitalize"> {{
                    blogtDate(cmsPageContent.creation_time) }} </span>
                                            </router-link>
                                            <!-- <h5>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt omnis
                                                error
                                                commodi doloremque rerum sit cumque, ducimus accusamus sed accusantium!
                                            </h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa culpa vero,
                                                modi omnis excepturi fuga deleniti minus explicabo dolorem reprehenderit
                                                commodi placeat nostrum assumenda, molestias ullam recusandae! Corporis
                                                totam cum voluptatum quibusdam nihil! Labore adipisci veniam, dolorum
                                                quisquam sit similique nemo illo. Inventore explicabo quam officiis eius
                                                qui
                                                non natus?</p> -->
                                            <!-- <div v-html="cmsPageContent.short_filtered_content"></div> -->
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 px-3">
                                        <img :src="cmsPageContent.first_image" :width="530" alt="blog"
                                            class="img-fluid" />
                                    </div> -->
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-12">
                                        <div v-html="cmsPageContent.filtered_content"></div>
                                    </div>
                                    <!-- <div class="col-md-12 px-3">
                                        <h5>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt omnis error
                                            commodi doloremque rerum sit cumque, ducimus accusamus sed accusantium!</h5>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa culpa vero,
                                            modi omnis excepturi fuga deleniti minus explicabo dolorem reprehenderit
                                            commodi placeat nostrum assumenda, molestias ullam recusandae! Corporis
                                            totam cum voluptatum quibusdam nihil! Labore adipisci veniam, dolorum
                                            quisquam sit similique nemo illo. Inventore explicabo quam officiis eius qui
                                            non natus?</p>
                                    </div>
                                    <div class="col-md-12 px-3 mt-2">
                                        <h6>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt omnis error
                                            commodi doloremque</h6>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta at provident
                                            aliquid. Necessitatibus unde deserunt earum molestias fuga debitis ad
                                            aperiam
                                            nobis eaque veritatis vitae obcaecati aspernatur, dolores ab? Sequi
                                            cupiditate
                                            beatae odio ducimus, aperiam veniam reprehenderit minima nesciunt minus,
                                            accusamus eligendi sint consequuntur vel consectetur quae enim. Sunt aliquid
                                            iure tenetur maxime quaerat voluptatibus debitis dolorum recusandae ducimus
                                            dolores ab possimus quod esse voluptatem tempora saepe praesentium aliquam
                                            doloremque cumque atque blanditiis beatae, reprehenderit et? Harum libero
                                            saepe
                                            soluta delectus esse corporis, at dolores ipsum tenetur sequi perspiciatis
                                            voluptatem? Quae voluptatem deleniti, quo error iusto in. Eveniet, sit
                                            voluptatum?</p>
                                    </div>
                                    <div class="col-md-12 px-3 mt-2">
                                        <h6>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt omnis error
                                            commodi doloremque</h6>
                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate soluta
                                            distinctio adipisci rerum asperiores consequatur laborum minus nisi sunt id
                                            nam
                                            optio inventore voluptatem sint, tenetur earum, ab fugiat hic nobis. Nam
                                            aperiam
                                            architecto laborum beatae ut nesciunt odit animi hic dicta, velit cupiditate
                                            atque sunt quia facilis est vitae nostrum iure, vero, porro soluta dolorum
                                            sit
                                            quaerat! Harum excepturi eveniet corrupti error iure eligendi natus
                                            distinctio
                                            repudiandae magni atque ad suscipit asperiores delectus quo nesciunt
                                            doloremque
                                            molestiae, ipsa, deserunt quia rem, dolorem quaerat odit doloribus dicta?
                                            Possimus, ducimus dolor suscipit tenetur praesentium culpa eligendi a alias.
                                            In,
                                            dolorum quae?</p>
                                    </div> -->
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- blogs -->
            <div class="pt-3">
                <blog-section title="related_articles" :blogs="blogs" v-if="loaded"></blog-section>
            </div>
            <!-- blogs -->
        </div>
    </main>
</template>
<script>

// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';
import BlogSection from '@/esf_weert_mobilesupplies/core/components/partial/home/BlogSection';
import Breadcrumbs from '@/esf_weert_mobilesupplies/core/components/elements/breadcrumbs.vue'
import config from '../../config';

export default {
    data: function () {
        return {
            blogs: [],
            loaded: false
        };
    },
    components: {
        BlogSection,
        Breadcrumbs
    },
    created: function () {
        this.getBlog();
    },
    computed: {
        cmsPageContent() {
            return this.$store.getters["blog/getBlog"];
        },
        identifier() {
            return this.$route.params.id;
        },
        dateFormat() {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return options;
        },
        shareUrl() {
            return config.app_url + this.$route.path.slice(1) + "/";
        },
        blogCategories() {
            return this.$store.getters["blog_additional/getBlogCategories"];
        },
        allFilteredBlogs() {
            return this.$store.getters["blog_additional/getFilteredBlogs"];
        },
        getSingleBlogContent() {
            return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
                "single_blog_content"
            );
        },
    },
    watch: {
        identifier() {
            this.$store.dispatch("blog/loadBlog", {
                identifier: this.$route.params.id,
            });
        },
    },
    async mounted() {
        await this.$store.dispatch("blog/loadBlog", {
            identifier: this.$route.params.id,
        });
        const bcrumb = {
            current: this.cmsPageContent.title,
            routes: [
                {
                    name: "Blog",
                    route_link: "/blog",
                },
            ],
        };

        this.$store.commit("breadcrumbs/set", bcrumb);
        await this.$store.dispatch("blog_additional/loadBlogCategories");
        document.addEventListener("click", this.close);
    },
    methods: {
        getBlog: async function () {
            this.loaded = false;
            this.blogs = this.$store.getters["home/getHomeBlogs"];
            this.loaded = true;
        },
        blogtDate(date) {
            let formattedDate = new Date(date).toLocaleDateString(
                "nl-NL",
                this.dateFormat
            );
            return formattedDate;
        },
        stripHTMLTags(html) {
            return html.replace(/<\/?[^>]+(>|$)/g, "");
        }
    },
    destroyed() {
        document.removeEventListener("click", this.close);
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: "title",
                    content: this.cmsPageContent
                        ? this.cmsPageContent.meta_title ?? this.cmsPageContent.title
                        : "Blog",
                },
                {
                    name: "keywords",
                    content: this.cmsPageContent
                        ? this.cmsPageContent.meta_keywords ?? ""
                        : "",
                },
                {
                    name: "description",
                    content: this.cmsPageContent
                        ? this.cmsPageContent.meta_description
                        : "Blog",
                },
            ],
            title: this.cmsPageContent
                ? this.cmsPageContent.meta_title ?? this.cmsPageContent.title
                : "Blog",
            link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
        };
    },
    //   methods: {
    //     handleHide() {
    //       this.visible = false;
    //     },
    //     showLightbox() {
    //       this.visible = true;
    //     },
    //     blogtDate(date) {
    //       let formattedDate = new Date(date).toLocaleDateString(
    //         "nl-NL",
    //         this.dateFormat
    //       );
    //       return formattedDate;
    //     },
    //     async searchBlog(event) {
    //       const searchVal = event.target.value;
    //       if (searchVal.length > 0) {
    //         this.isFilterActive = true;
    //         await this.$store.dispatch("blog_additional/loadBlogsByFilter", {
    //           searchVal,
    //         });
    //         this.filteredBlogs = this.allFilteredBlogs.slice(0, this.blogsPerPage);
    //       } else {
    //         this.isFilterActive = false;
    //       }
    //     },
    //     goToCategory(category) {
    //       this.$router.push({
    //         name: "blog-category",
    //         params: { category_id: category.identifier },
    //       });
    //     },
    //     goToBlog(identifier) {
    //       this.$router.push("/blog/" + identifier + "/");
    //       this.isFilterActive = false;
    //     },
    //     onFocus(event) {
    //       if (event.target.value != "") {
    //         this.isFilterActive = true;
    //       }
    //     },
    //     close(e) {
    //       const element = this.$refs.blogSearch;
    //       if (!element.parentElement.contains(e.target)) {
    //         this.isFilterActive = false;
    //       }
    //     },
    //     setDefaultImage(event) {
    //       event.target.src = this.xlLogo;
    //     },
    //   },
};
</script>